import { Link } from 'react-router-dom'
import { useTranslate } from '../../lib/utils'
import useStore from '../../store'

import styles from './nav.module.scss'

export default function Nav() {
  const t = useTranslate();
  const setLang = useStore((state) => state.setLang)
  const lang = useStore((state) => state.lang)
  const countryFromPathname = useStore((state) => state.countryFromPathname)

  const closeMenu = () => {
    document.body.classList.remove('menu-open')
  }

  const chooseLang = (item) => {
    console.log(item.target.innerText.toLowerCase());
    setLang(item.target.innerText.toLowerCase());
  }

  return (
    <nav className={styles.block}>
      <div className={styles.name}>
        Menu
      </div>
      <button className={styles.close} onClick={closeMenu}>
        X
      </button>
      <div className={styles.content}>
        <ul className={styles.menu}>
          <li>
            <a href={`/${countryFromPathname}`}>
              {t("back_to_the_odyssey")}
            </a>
          </li>
          <li>
            <Link to="/why" onClick={closeMenu}>
              {t("why_this_site")}
            </Link>
          </li>
          <li>
            <Link to="/personal-data" onClick={closeMenu}>
              {t("personal_data")}
            </Link>
          </li>
          <li>
            <Link to="/legal-mentions" onClick={closeMenu}>
              {t("legal_notices")}
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMenu}>
              {t("contact_us")}
            </Link>
          </li>
          <li>
            <span className={lang === "fr" ? styles.active : ''} onClick={chooseLang}>FR</span>
            &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <span className={lang === "en" ? styles.active : ''} onClick={chooseLang}>EN</span>
          </li>
        </ul>
      </div>
    </nav>
  )
}
