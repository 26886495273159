import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'
import useStore from '../../store'
import { useTranslate } from '../../lib/utils'

import styles from './rating.module.scss'



export default function Rating() {
  const rating = useStore((state) => state.rating)
  const setRating = useStore((state) => state.setRating)
  const t = useTranslate();

  const expLabels = [t("unacceptable"), t("disappointing_fem"), t("acceptable"), t("pleasant"), t("fabulous_fem")]

  return (
    <>
      <RadioGroup className={styles.block} as="ol" value={rating} onChange={setRating}>
        {expLabels.map((_, option) => (
          <RadioGroup.Option value={option} key={option} className={styles.item} as="li">
            {({ active, checked }) => (
              <span
                className={classNames(active ? styles.active : styles.icon, {
                  [styles.checked]: checked || option < rating,
                })}></span>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <p className={styles.label}>{rating === null ? '' : expLabels[rating]}</p>
    </>
  )
}
