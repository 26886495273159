import classNames from 'classnames'
import { useState } from 'react'

import styles from './checkbox.module.scss'

export default function Checkbox({ children, name, control }) {
  const [checked, setChecked] = useState(false)

  return (
    <label className={styles.block}>
      <input type="checkbox" {...control.register(name, { required: true, onChange: () => setChecked(!checked) })} />
      <span aria-hidden="true" className={classNames(styles.control, { [styles.checked]: checked })}></span>
      <span className={styles.label}>{children}</span>
    </label>
  )
}
