import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Rating from '../../components/rating/rating'
import useStore from '../../store'

import styles from './modal.module.scss'
import classNames from 'classnames'

export default function Modal() {
  const ally = useStore((state) => state.ally)
  const setAlly = useStore((state) => state.setAlly)
  const rating = useStore((state) => state.rating)
  const modalStep = useStore((state) => state.modalStep)
  const setModalStep = useStore((state) => state.setModalStep)
  const { register, handleSubmit } = useForm()
  const setComment = useStore((state) => state.setComment)
  const navigate = useNavigate()

  const closeModal = () => {
    document.body.classList.remove('modal-open')
  }

  const nextStep = () => {
    setModalStep(2)
  }

  const onSubmit = async (formData) => {
    setComment(formData.comment)
    closeModal()

    if (!ally) {
      const random = Math.floor(Math.random() * 4) + 1
      setAlly(random)
      document.body.dataset.ally = random
    }

    navigate('/personal-details')
  }

  return (
    <div className={styles.block}>
      <div className="b-wrapper b-wrapper--center">
        <div className={styles.content}>
          <h2 className={styles.title}>
            L’Odyssée <br />
            en Hermès
          </h2>
          {modalStep === 1 && (
            <>
              <p>
                Souhaitez-vous vraiment <br />
                quitter l’aventure ?
              </p>
              <button type="button" className="b-btn" onClick={closeModal}>
                Non, je continue !
              </button>
              <p>
                Avant de nous quitter, <br />
                laissez-nous votre avis !
              </p>
              <div className="b-wrapper__btn">
                <button className="b-btn b-btn--full" onClick={nextStep}>
                  Je laisse un avis
                </button>
                <Link to="/" className="b-btn b-btn--full b-btn--secondary" onClick={closeModal}>
                  J’arrête sans laisser mon avis
                </Link>
              </div>
            </>
          )}
          {modalStep === 2 && (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <p>Comment s’est passée votre expérience ?</p>
                <Rating />
                <p>Laissez-nous un commentaire</p>
                <textarea
                  {...register('comment')}
                  placeholder="Laissez votre commentaire ici..."
                  className="b-control b-control--textarea"></textarea>
                <div className="b-wrapper__btn">
                  <button className={classNames('b-btn b-btn--full', { 'b-btn--disabled': rating === null })}>
                    Continuer
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
