import classNames from 'classnames';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Steps from '../../components/steps/steps';
import useStore from '../../store';
import {useTranslate} from '../../lib/utils'    

import styles from './bravo.module.scss';


export default function Bravo() {
  const navigate = useNavigate();
  const setWhiteNav = useStore((state) => state.setWhiteNav);
  const ally = useStore((state) => state.ally);
  const t = useTranslate();

  useEffect(() => {
    if (!ally) {
      navigate('/');
    }
    setWhiteNav(false);
  }, [setWhiteNav, ally, navigate]);

  return !ally ? (
    <></>
  ) : (
    <div className={classNames('b-wrapper', styles.page)}>
      <Steps />
      <div className={styles.textWrapper}>
        <div className={styles.wrapper}>
          <h2>{t("title_bravo")}</h2>
            <p>{t("congratulations")}</p>
          {/* <div className={styles.ally}>
            <img src={require(`../../assets/images/thumb-ally${ally}.png`)} alt="" />
          </div> */}
        </div>
          <div className="b-wrapper__btn">
            <Link to="/personal-details" className={`b-btn ${styles.bravoButton}`}>
              {t("click_on_the_next_screen")}
            </Link>
          </div>
      </div>
    </div>
  );
}


// import classNames from 'classnames'
// import { useEffect } from 'react'
// import { Link, useNavigate } from 'react-router-dom'
// import Steps from '../../components/steps/steps'
// import useStore from '../../store'

// import styles from './bravo.module.scss'

// const allyPhrases = [
//   'Nagez dans le bonheur !',
//   'Quel coup de maître ! ',
//   'Hennissez de joie !',
//   'Rugissez de plaisir !',
// ]

// export default function Bravo() {
//   const navigate = useNavigate()
//   const setWhiteNav = useStore((state) => state.setWhiteNav)
//   const ally = useStore((state) => state.ally)

//   useEffect(() => {
//     if (!ally) {
//       navigate('/')
//     }
//     setWhiteNav(false)
//   }, [setWhiteNav, ally, navigate])

//   return !ally ? (
//     <></>
//   ) : (
//     <div className={classNames('b-wrapper', styles.page)}>
//       <div className={styles.wrapper}>
//         <h2>{allyPhrases[ally - 1]}</h2>
//         <p>Bravo, vous êtes au terme de cette Odyssée. Pour garder votre compagnon à vos côtés...</p>
//         <Steps />
//         <div className={styles.ally}>
//           <img src={require(`../../assets/images/thumb-ally${ally}.png`)} alt="" />
//         </div>
//       </div>
//       <div className="b-wrapper__btn">
//         <Link to="/personal-details" className="b-btn">
//           Cliquez sur l’écran suivant
//         </Link>
//       </div>
//     </div>
//   )
// }
