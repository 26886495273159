import create from 'zustand'
import axios from 'axios'
import labelsData from '../pages/labels.json'

const getCountryFromUrl = () => {
  const countryFromPathname = window.location.pathname.slice(1);

  return countryFromPathname;
}

const initialState = {
  questions: [],
  numQuestions: 0,
  heartQuestion: null,
  answers: [],
  isLoading: true,
  shopId: 12,
  comment: '',
  shopName: null,
  ally: 0,
  modalStep: 1,
  showLogo: true,
  rating: null,
  whiteNav: false,
  interest: null,
  interests: [],
  labels: labelsData,
  anecdotes: [],
  anecdotesDisplayed: [],
  lang: 'fr',
  country: null,
  countryFromPathname: getCountryFromUrl(),
  shops: [
    { id: 2, name: 'Aix-en-Provence' },
    { id: 4, name: 'Lyon' }
  ],
  currentTicketId: null,
  currentCampaign: []
}

const useStore = create((set, get) => ({
  questions: initialState.questions,
  numQuestions: initialState.numQuestions,
  heartQuestion: initialState.heartQuestion,
  answers: initialState.answers,
  isLoading: initialState.isLoading,
  shopId: initialState.shopId,
  shopName: initialState.shopName,
  comment: initialState.comment,
  ally: initialState.ally,
  modalStep: initialState.modalStep,
  showLogo: initialState.showLogo,
  rating: initialState.rating,
  whiteNav: initialState.whiteNav,
  interests: initialState.interests,
  labels: initialState.labels,
  anecdotes: initialState.anecdotes,
  anecdotesDisplayed: initialState.anecdotesDisplayed,
  lang: initialState.lang,
  country: initialState.country,
  countryFromPathname: initialState.countryFromPathname,
  shops: initialState.shops,
  currentCampaign: initialState.currentCampaign,
  currentTicketId: initialState.currentTicketId,

  getSelectedInterest: () => {
    return get().interest
  },

  fetchQuestionsAndAnecdotes: async () => {
    set((state) => ({ isLoading: true }))
    try {
      const apiUrl =
        process.env.REACT_APP_API_URL + `questions`
      const { data } = await axios.get(apiUrl)
      const all_questions = data['hydra:member'];
      all_questions.forEach(item => {
        //todo: retirer

        item.label = JSON.parse(item.label.replaceAll('"Feux d\'artifice"', "Feux d'artifice"));

        if (item.relatedLabels.length) {

          item.relatedLabels = JSON.parse(item.relatedLabels); //.replaceAll(";", ",")) ;
        }

      });
      const questions = all_questions.filter(q => q.questionType.id + "" === "2").sort((a, b) => { return a.position > b.position })
      const anecdotes = all_questions.filter(q => q.questionType.id + "" === "1").sort((a, b) => { return a.position > b.position })
      const heartQuestion = all_questions.find(q => q.questionType.id + "" === "3");

      set((state) => ({
        ...state,
        isLoading: false,
        questions: questions,
        numQuestions: questions.length,
        anecdotes: anecdotes,
        heartQuestion: heartQuestion
      }
      ))
    } catch (error) {
      console.log(error)
    }
  },

  fetchInterests: async () => {
    try {

      const apiUrl = process.env.REACT_APP_API_URL + 'visit_reasons'
      const { data } = await axios.get(apiUrl)
      var interests = data['hydra:member'];
      interests.sort((a, b) => { return a.sort > b.sort })
      interests.forEach(item => {
        // todo retirer replace all
        item.name = JSON.parse(item.name.replaceAll("=>", ":"));
      })
      const mainInterests = interests.filter((item) => !item.parent)
      const subInterests = interests.filter((item) => item.parent)
      interests = mainInterests.map((main) => {
        const sub = subInterests
          .filter((item) => item.parent.id === main.id)
          .map((sub) => ({ name: sub.name, id: sub.id }))

        return {
          name: main.name,
          id: main.id,
          ...(sub.length && { sub }),
        }
      })

      set((state) => ({ ...state, interests }))
    } catch (error) {
      console.log(error)
    }
  },

  fetchShops: async () => {
    try {

      const apiUrl = process.env.REACT_APP_API_URL + 'shops'
      const { data } = await axios.get(apiUrl)
      var shops = data['hydra:member'];
      //stores.sort((a,b)=>{return a.sort>b.sort})
      const country = get().country;

      shops = shops.filter((item) => item.country.isoCode === country);

      /*stores = mainInterests.map((main) => {
        const sub = subInterests
          .filter((item) => item.parent.id === main.id)
          .map((sub) => ({ name: sub.name, id: sub.id }))

        return {
          name: main.name,
          id: main.id,
          ...(sub.length && { sub }),
        }
      })*/

      set((state) => ({ ...state, shops }))
    } catch (error) {
      console.log(error);
    }
  },


  fetchCurrentCampaign: async () => {
    try {

      const apiUrl = process.env.REACT_APP_API_URL + 'campaigns'
      const { data } = await axios.get(apiUrl)
      var currentCampaign = data['hydra:member'];
      //stores.sort((a,b)=>{return a.sort>b.sort})
      const country = get().country;
      const currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString();

      currentCampaign = currentCampaign.filter((item) => item.country.isoCode === country);
      currentCampaign = currentCampaign.find((c) => currentDate >= c.dateStart && currentDate <= c.dateEnd);
      set((state) => ({ ...state, currentCampaign }))
    } catch (error) {
      console.log(error);
    }
  },

  addAnswer: (answer) => set((state) => ({ answers: [...state.answers, answer] })),
  updateAnswer: (id, rating) =>
    set((state) => {
      const answers = state.answers.map((answer) => {
        if (answer.question === id) {
          return { ...answer, rating }
        }
        return answer
      })

      return { answers }
    }),
  emptyAnswers: () => set(() => ({ answers: [] })),
  setComment: (comment) => set(() => ({ comment })),
  setShopId: (shopId) => set(() => ({ shopId })),
  setShopName: (shopName) => set(() => ({ shopName })),
  setAlly: (ally) => set(() => ({ ally })),
  setLogoVisibility: (showLogo) => set(() => ({ showLogo })),
  setRating: (rating) => set(() => ({ rating })),
  setWhiteNav: (whiteNav) => set(() => ({ whiteNav })),
  setModalStep: (modalStep) => set(() => ({ modalStep })),
  setInterest: (interest) => set(() => ({ interest })),
  setLang: (lang) => set(() => ({ lang })),
  setCountry: (country) => set(() => ({ country })),
  setCurrentTicketId: (currentTicketId) => set(() => ({ currentTicketId })),
  addAnecdoteDisplayed: (idx) => set(state => ({
    anecdotesDisplayed: [...state.anecdotesDisplayed, idx]
  }))
}))

export default useStore
