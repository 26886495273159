import classNames from 'classnames'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useStore from '../../store'

import Steps from '../../components/steps/steps'

import styles from './question.module.scss'

const challenges = ['Le cheval de course', 'Le cheval ailé', 'Le super cheval', 'Le cheval messager', 'Le cheval génie']

export default function Question() {
  const numQuestions = useStore((state) => state.numQuestions)
  const setWhiteNav = useStore((state) => state.setWhiteNav)
  const ally = useStore((state) => state.ally)
  const params = useParams()
  const id = Number(params.id)
  const remaining = numQuestions + 1 - id
  const navigate = useNavigate()

  useEffect(() => {
    if (numQuestions === 0) {
      navigate('/')
    }

    setWhiteNav(false)
    //const timeoutID = setTimeout(() => {
    navigate(`/question/${id}/opinion`)
    //}, 3000)

    //return () => clearTimeout(timeoutID)
  })

  return !numQuestions ? (
    <></>
  ) : (
    <div className={classNames('b-wrapper b-wrapper--with-padding b-wrapper--center', styles.page)}>
      <div className={styles.steps}>
        <Steps num={id} />
      </div>
      <div className={styles.circle}>
        <img src={require(`../../assets/images/a${ally}-q${id}.png`)} alt="" />
      </div>
      <p className={styles.start}>ESCALE {id}</p>
      <h2>{challenges[parseInt(id) - 1]}</h2>
      <p>{remaining > 1 ? `Encore ${remaining} escales` : 'Dernière escale'}</p>
    </div>
  )
}
