import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useStore from '../../store'
import { useTranslate } from '../../lib/utils'

import styles from './home.module.scss'
import push from '../../assets/images/illu-intro.png'
//import poster from '../../assets/images/illu-intro-video.png'

export default function Home(props) {
  const [started, setStarted] = useState(false)
  const fetchInterests = useStore((state) => state.fetchInterests);
  const fetchQuestionsAndAnecdotes = useStore((state) => state.fetchQuestionsAndAnecdotes);
  const fetchShops = useStore((state) => state.fetchShops);
  const fetchCurrentCampaign = useStore((state) => state.fetchCurrentCampaign);
  const setCountry = useStore((state) => state.setCountry);
  const country = useStore((state) => state.country);
  const setLang = useStore((state) => state.setLang);
  const shops = useStore((state) => state.shops);
  const currentCampaign = useStore((state) => state.currentCampaign);
  const t = useTranslate();

  function isOpened(country) {
    if (country && currentCampaign) {

      const start = currentCampaign.dateStart;
      const now = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString();
      const end = currentCampaign.dateEnd;
      //end.setDate(end.getDate() + 1);

      //return true;
      return now >= start && now <= end;
    }

    return false;
  }

  useEffect(() => {

    if (!country) {
      setCountry(props.country);
      setLang(props.lang);
    }

    async function fetchDatasFromApiToStore() {
      await fetchShops();
      await fetchCurrentCampaign();
      await fetchInterests();
      await fetchQuestionsAndAnecdotes();
    }

    fetchDatasFromApiToStore()



  }, [country, setCountry, setLang, fetchInterests, fetchQuestionsAndAnecdotes, fetchShops, fetchCurrentCampaign, props.country, props.lang])

  console.log("livraison 06/09");
  return (

    (shops && shops[0]?.country && isOpened(shops[0].country)
      ? !started ? (
          <div className={styles.anim} onClick={() => setStarted(true)}>
            <video autoPlay={true} muted={true} playsInline={true} onEnded={() => setStarted(true)}>
              <source type="video/mp4" src={`${process.env.PUBLIC_URL}/anims/intro.mp4`} />
            </video>
          </div>
        ) : (
          <div className="b-wrapper b-wrapper--center">
            <div className={styles.content}>
              <div className={styles.push}>
                <img src={push} alt="" />
              </div>

              <p>
                {t("tell_us_about_your_exp")}
              </p>
            </div>
            <div className="b-wrapper__btn">
              <Link to="/choose" className="b-btn">
                {t("start_the_odyssey")}
              </Link>
            </div>
          </div>
        )
      : (
        <div className="b-wrapper b-wrapper--center">
          <div className={styles.content}>
            <div className={styles.push}>
              <img src={push} alt="" />
            </div>

            <div>
              <p className={styles.campaign_off} dangerouslySetInnerHTML={{ __html: t("campagne_off") }} />
            </div>
          </div>
        </div>
      )
    )

  )
}
