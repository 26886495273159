import { useState, Fragment } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import useStore from '../../store'


import styles from './selectbox.module.scss'

export default function Selectbox({ placeholder, options, onUpdate }) {
  const [selectedOption, setSelectedOption] = useState()
  const lang = useStore((state) => state.lang)

  const updateValue = (value) => {
    setSelectedOption(value)
    onUpdate(value)
  }

  return (
    <div className={styles.block}>
      <Listbox value={selectedOption} onChange={updateValue}>
        <Listbox.Button className={styles.button}>{selectedOption ? (selectedOption.name[lang] ? selectedOption.name[lang] : selectedOption.name) : placeholder}</Listbox.Button>
        <Listbox.Options className={styles.options}>
          {options.map((option) => (
            <Listbox.Option key={option.id} value={option} as={Fragment}>
              {({ active, selected }) => (
                <li
                  className={classNames(active ? styles.active : styles.option, {
                    [styles.selected]: selected,
                  })}>
                  {option.name[lang] ? option.name[lang] : option.name}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
