import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Selectbox from '../../components/selectbox/selectbox'
import axios from 'axios'
//import AutocompleteSelectbox from '../../components/selectbox/autocompleteSelectbox'
import useStore from '../../store'
import { useTranslate } from '../../lib/utils'

import Rating from '../../components/rating/rating'


export default function Info() {
  const navigate = useNavigate()
  const [interest, setInterest] = useState()
  const [sub, setSub] = useState()
  const setLogoVisibility = useStore((state) => state.setLogoVisibility)
  const setShopId = useStore((state) => state.setShopId)
  const currentCampaign = useStore((state) => state.currentCampaign)
  const setCurrentTicketId = useStore((state) => state.setCurrentTicketId)
  const setShopName = useStore((state) => state.setShopName)
  const shopId = useStore((state) => state.shopId)
  //const currentTicketId = useStore((state) => state.currentTicketId)
  const isLoading = useStore((state) => state.isLoading)
  const rating = useStore((state) => state.rating)
  const interests = useStore((state) => state.interests)
  const setStoreInterest = useStore((state) => state.setInterest)
  const setWhiteNav = useStore((state) => state.setWhiteNav)
  const ally = useStore((state) => state.ally)
  const shops = useStore((state) => state.shops)
  const shopName = useStore((state) => state.shopName)
  const lang = useStore((state) => state.lang)
  const t = useTranslate();
  const answers = useStore((state) => state.answers)
  const addAnswer = useStore((state) => state.addAnswer)
  const updateAnswer = useStore((state) => state.updateAnswer)
  const heartQuestion = useStore((state) => state.heartQuestion)

  const filteredShops = shops.filter((shop) => shop.inCampaign);

  useEffect(() => {
    if (ally === 0 || !interests.length) {
      navigate('/')
    }

    setWhiteNav(ally === 1)
  }, [setWhiteNav, ally, interests, navigate])

  useEffect(() => {
    setLogoVisibility(false)

  }, [setLogoVisibility])



  const onUpdate = async (value) => {

    if (value) {
      setShopId(value.id)
      setShopName(value.name)
      //await fetchQuestions()
    }
  }




  const handleInterest = async (value) => {
    setStoreInterest(value)
    setInterest(value)
    setSub(null)

    if (value && value.sub) {
      value.sub.sort(function (a, b) {
        return a.name[lang].localeCompare(b.name[lang]);
      });
    }
    //await fetchQuestions()


  }


  const handleSub = async (value) => {
    setStoreInterest(value)
    setSub(value)
    //await fetchQuestions()
  }


  const handleClick = async (event) => {
    // ouvrir un ticket
    const apiUrl = process.env.REACT_APP_API_URL + 'tickets'
    console.log(currentCampaign)
    console.log("curr camp id: " + currentCampaign.id)
    console.log("curr shop id: " + shopId)

    const data = {
      //      ...formData,
      shop: `/api/shops/${shopId}`,
      isDraft: true,
      campaign: `/api/campaigns/${currentCampaign.id}`,
      //      ticketAnswers,
      //      comment,
      ...(interest && { visitReason: `/api/visit_reasons/${sub ? sub.id : interest.id}` }),
    }

    await setCurrentTicketId(null)

    try {
      axios.post(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        setCurrentTicketId(response.data.id);
        //console.log(currentCampaign);
        // sauver la note
        const answer = (answers || []).find((item) => item.question === heartQuestion['@id'])
        const currentAnswer = { question: heartQuestion['@id'], rating: rating + 1 }
        if (answer) {
          updateAnswer(heartQuestion['@id'], rating + 1);
        } else {
          addAnswer(currentAnswer);
        }
        // Envoyer la note
        axios.put(process.env.REACT_APP_API_URL + 'tickets/' + response.data.id,
          { ticketAnswers: [currentAnswer] },
          { headers: { 'Content-Type': 'application/json' } }
        )
      })
      //  emptyAnswers()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={classNames('b-wrapper', 'b-wrapper--no-padding', 'u-ally')}>
      <div className="b-bottom-sheet">
        <div className="b-bottom-sheet__content">
          <h2>{t("let_s_meet")}</h2>

          <p className="u-mt-6">{t("in_which_store")}</p>
          <Selectbox placeholder={t('select')} options={filteredShops} value={shopName} onUpdate={onUpdate} />
          <p className="u-mt-6">{t("purpose_visit")}</p>
          <Selectbox placeholder={t('select')} options={interests} onUpdate={handleInterest} />
          {interest && interest.sub && (
            <Selectbox placeholder={t('select')} options={interest.sub} onUpdate={handleSub} />
          )}
          <p className="u-mt-6" dangerouslySetInnerHTML={{ __html: heartQuestion ? heartQuestion.label[lang] : "" }}></p>
          <Rating />
          <div className="b-bottom-sheet__btn">

            <Link
              onClick={handleClick}
              to="/question/1"
              className={classNames('b-btn', {
                'b-btn--disabled':
                  shopId === 0 || shopName === null || isLoading || !interest || rating === null || (!!interest.sub && !sub),
              })}>
              {t("continue")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
