import classNames from 'classnames'

import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Radios from '../../components/radios/radios'
import Checkbox from '../../components/checkbox/checkbox'
import axios from 'axios'
import useStore from '../../store'
import { useTranslate } from '../../lib/utils'

import styles from './personal-details.module.scss'


export default function PersonalDetails() {
  const rating = useStore((state) => state.rating)
  const questions = useStore((state) => state.questions)
  const heartQuestion = useStore((state) => state.heartQuestion)
  const answers = useStore((state) => state.answers)
  const currentTicketId = useStore((state) => state.currentTicketId)
  const ally = useStore((state) => state.ally)
  //const emptyAnswers = useStore((state) => state.emptyAnswers)
  const [loading, setLoading] = useState(false)
  const { register, control, watch, handleSubmit } = useForm()
  const navigate = useNavigate()
  const shouldRedirect = !ally || !answers
  const t = useTranslate();

  const civilities = [t("ms"), t("mr")];


  // useEffect(() => {
  //   if (shouldRedirect) {
  //     navigate('/')
  //   }

  //   setWhiteNav(ally === 1)
  // }, [setWhiteNav, ally, shouldRedirect, navigate])

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/');
    }

    // Retirer la ligne suivante pour que le burger ne devienne pas blanc sur cette page
    // setWhiteNav(ally === 1);
  }, [shouldRedirect, navigate]);


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (rawPhone) => {
    let phone = String(rawPhone).replace(/ /g, '');
    /*
    phone = phone.replace('+33', '0');
    phone = phone.replace('0033', '0');
    if (!phone) {
      return true;
    }
    if (phone.length === 10 &&
      (phone.substring(0, 2) === '01' || phone.substring(0, 2) === '02' || phone.substring(0, 2) === '03'
        || phone.substring(0, 2) === '04' || phone.substring(0, 2) === '05' || phone.substring(0, 2) === '06' || phone.substring(0, 2) === '07')) {
      return true;
    }*/

    var regexp = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/
    return regexp.test(phone)
  };

  const onSubmit = async (formData) => {
    console.log("submit", questions, heartQuestion, rating, answers);
    if (loading) return

    if (formData) {
      if (!validateEmail(formData.email) && formData.email !== "") {
        alert('Invalid email.');
        return
      }

      if (!validatePhone(formData.phone) && formData.phone !== "") {
        alert('Invalid phone number.');
        return
      }
    }

    if (formData && formData.email === "" && formData.phone === "") {
      alert('Please fill in your phone number or email.');
      return
    }

    setLoading(true)
    const data = { ...formData, isDraft: null }
    const apiUrl = process.env.REACT_APP_API_URL + 'tickets/' + currentTicketId


    // const res = { status: 201 }
    try {
      axios.put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      //emptyAnswers()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      navigate('/end')
    }

    //const ticketAnswers = answers

    /*if (questions.length) {

      //const question = questions.filter((question) => question.position === 0)[0]
      ticketAnswers.push({
        question: heartQuestion['@id'],
        rating: rating + 1,
      })
    }*/


    /*const data = {
      ...formData,
      shop: `/api/shops/${shopId}`,
      ticketAnswers,
      comment,
      isDraft: null,
      ...(interest && { visitReason: `/api/visit_reasons/${interest.id}` }),
    }
    const apiUrl = process.env.REACT_APP_API_URL + 'tickets'
    */

  }

  return shouldRedirect ? (
    <></>
  ) : (
    <div className={classNames('b-wrapper', 'b-wrapper--no-padding', 'u-ally', styles.page)}>
      <div className="b-bottom-sheet">
        <div className="b-bottom-sheet__content">
          <h2>{t("would_you_like_to_be_contacted")}</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.wrapper}>
              <label className={classNames(styles.label, styles.label_inline)}>{t("title")}</label>
              <div className={styles.radios} >
                <Radios options={civilities} control={control} name="civ" {...register('gender')} />
              </div>

              <label className={styles.label}>{t("surname")}</label>
              <input type="text" className="b-control" required={true} {...register('lastname')} />
              <label className={styles.label}>{t("first_name")}</label>
              <input type="text" className="b-control" required={true} {...register('firstname')} />
              <label className={styles.label}>{t("email")}</label>
              <input
                type="email"
                className="b-control"
                placeholder="email@domain.com"
                {...register('email')}
              />
              <label className={styles.label}>{t("phone_number")}</label>
              <input type="tel" className="b-control" placeholder="+33 X XX XX XX XX" {...register('phone')} />
              <Checkbox control={control} name="contact">
                {t("authorize_to_contact")}
                <Link to="/personal-data"> {t("click_here")}</Link>.
              </Checkbox>
            </div>
            <div className="b-bottom-sheet__btn">
              <button className={classNames('b-btn', { 'b-btn--disabled': !watch('contact') })}>{t("submit")}</button>
              <button type="button" className={styles.skip} onClick={() => onSubmit()}>
                {t("skip_this_step")}
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}
