import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { Controller } from 'react-hook-form'

import styles from './radios.module.scss'

export default function Radios({ options, control, name }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup className={styles.group} {...field}>
          {options.map((option) => (
            <RadioGroup.Option value={option} key={option} className={styles.wrapper}>
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(active ? styles.active : styles.option, {
                      [styles.checked]: checked,
                    })}>
                    <span></span>
                    {option}
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      )}
    />
  )
}
