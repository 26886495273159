import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'
import useStore from '../../store'

import styles from './ratingquestion.module.scss'
import {useEffect} from "react";


const expLabels = ['Décevante', 'Convenable', 'Agréable', 'Réjouissante', 'Merveilleuse']

export default function RatingQuestion({ onUpdate, labelLeft, labelRight }) {

    const rating = useStore((state) => state.rating)
    const setRating = useStore((state) => state.setRating)

    const updateValues = (value) => {
        onUpdate(value);
        setRating(value);
    }

    useEffect(() => {
        setRating(null);
    }, [ setRating ])

      return (
        <>
          <RadioGroup className={styles.block} as="ol" value={rating} onChange={updateValues}>
            {expLabels.map((_, option) => (
              <RadioGroup.Option value={option} key={option} className={styles.item} as="li">
                {({ active, checked }) => (
                  <span
                    className={classNames(active ? styles.active : styles.icon, {
                      [styles.checked]: checked || option < rating,
                    })}></span>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
          <p className={styles.label}>
              {labelLeft}
              <span>{labelRight}</span>
          </p>
        </>
      )
}
