import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import useStore from '../../store'

import styles from './question-details.module.scss'

export default function QuestionDetails() {
  const params = useParams()
  const navigate = useNavigate()
  const id = Number(params.id)
  const questions = useStore((state) => state.questions)
  const setWhiteNav = useStore((state) => state.setWhiteNav)
  const question = questions.filter((question) => question.questionType.id === 1)[id - 1]

  useEffect(() => {
    if (questions.length === 0) {
      navigate('/')
    }

    if (id === 5) {
      setWhiteNav(true)
    }
  }, [setWhiteNav, id, questions, navigate])

  const [answer, setAnswer] = useState()

  const isValidAnswer = (answer) => {
    return question.answers.find((answer) => answer.valid === true) === answer
  }

  return !questions.length ? (
    <></>
  ) : (
    <div className={classNames('b-wrapper', 'b-wrapper--no-padding', `u-bg-${id}`)} data-question={id}>
      <div className={classNames('b-bottom-sheet', styles.sheet)}>
        <div className="b-bottom-sheet__content">
          <div className={styles.inner}>
            <div className={styles.wrapper}>
              {answer ? (
                <>
                  <div>
                    {isValidAnswer(answer) ? (
                      <>
                        <div className={styles.icon}>
                          <img src={require(`../../assets/images/picto-win${id}.png`)} alt="" />
                        </div>
                        <h2>Victoire !</h2>
                        <p>{question.answerExplanation}</p>
                      </>
                    ) : (
                      <>
                        <div className={styles.icon}>
                          <img src={require(`../../assets/images/picto-lose${id}.png`)} alt="" />
                        </div>
                        <h2>Partie remise !</h2>
                        <p>{question.answerExplanation}</p>
                      </>
                    )}
                    <p>{question.info}</p>
                  </div>
                  <div className={styles.btn}>
                    <Link to={`/question/${id}/opinion`} className="b-btn">
                      Continuer
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h2 className={styles.subtitle}>Defi {id} - {id === 1 ? ( <><span>Le temps Hermès</span></> ) : ( <></> )}
                      {id === 2 ? ( <><span>L’accueil Hermès</span></> ) : ( <></> )}
                      {id === 3 ? ( <><span>Les attentions Hermès</span></> ) : ( <></> )}
                      {id === 4 ? ( <><span>Les histoires Hermès</span></> ) : ( <></> )}
                      {id === 5 ? ( <><span>L’expérience Hermès</span></> ) : ( <></> )}
                    </h2>
                    <p>{question.label}</p>
                  </div>
                  <ul className={styles.answers}>
                    {question.answers.map((answer, i) => (
                      <li className={styles.answer} key={i}>
                        <button className={styles.button} onClick={() => setAnswer(answer)}>
                          {answer.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.anim}>
          <video autoPlay={true} muted={true} loop={true} playsInline={true}>
            <source type="video/mp4" src={`${process.env.PUBLIC_URL}/anims/anim${id}.mp4`} />
          </video>
        </div>
      </div>
    </div>
  )
}
