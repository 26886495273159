import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import styles from './end.module.scss'
import useStore from '../../store'
import {useTranslate} from '../../lib/utils'

export default function End() {
  const navigate = useNavigate()
  const ally = useStore((state) => state.ally)

  const shopName = useStore((state) => state.shopName)
  const setWhiteNav = useStore((state) => state.setWhiteNav)
  const shouldRedirect = !ally

  const t = useTranslate();

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/')
    }
    setWhiteNav(false)
  }, [setWhiteNav, shouldRedirect, navigate])

  return shouldRedirect ? (
    <></>
  ) : (
    <div className={"b-wrapper " + styles.content}>
    <p dangerouslySetInnerHTML={{ __html: t("thank_you_download").replace("{store city}", shopName) }} />
        <a className="b-btn" href={require(`../../assets/images/dl${ally}.png`)} target="_blank" rel="noreferrer">
        {t("download_our_wallpaper")}
      </a>
    </div>
  )
}
