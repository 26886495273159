import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import useStore from '../../store'

import logo from '../../assets/images/logo_Hermes.svg'
import { ReactComponent as IconClose } from '../../assets/images/close.svg'
import { ReactComponent as IconBack } from '../../assets/images/back.svg'

import styles from './header.module.scss'

//const excludeQuit = ['/personal-details', '/', '/france', '/es', '/download', '/end', '/bravo']

const cmsPages = ['/why', '/personal-data', '/legal-mentions', '/contact']
const showLanguages = ['/', '/fr', '/ch', '/uk', '/us', '/hk']

const excludeBack = [
  '/', '/france', '/fr', '/ch', '/uk', '/us', '/hk',
  '/choose',
  '/info',
  '/end',
  '/why',
  '/personal-data',
  '/legal-mentions',
  '/contact',
  '/question/1/opinion',
  '/question/2/opinion',
  '/question/3/opinion',
  '/question/4/opinion',
  '/question/5/opinion',
  '/bravo',
  '/personal-details'
]

export default function Header() {
  const showLogo = useStore((state) => state.showLogo)
  const whiteNav = useStore((state) => state.whiteNav)
  const location = useLocation()
  const navigate = useNavigate()
  const lang = useStore((state) => state.lang)
  const setLang = useStore((state) => state.setLang)
  const { pathname } = location

  const chooseLang = (item) => {
    setLang(item.target.innerText.toLowerCase());
    /*Array.from(document.querySelectorAll("header ul li")).forEach(
      (el) => el.classList.remove("active")
    );
    item.target.classList.add("active");*/
  }

  const openMenu = () => {
    document.body.classList.add('menu-open')
    document.body.classList.remove('modal-open')
  }

  const toggleModal = () => {
    document.body.classList.toggle('modal-open')
  }

  return (
    <header className={styles.block} data-whitenav={whiteNav}>
      <div className={styles.wrapper}>
        <img src={logo} alt="Hermès" className={classNames(styles.logo, { [styles.hidden]: !showLogo })} />
        <div className={styles.btns}>
          <button
            className={classNames(styles.btn, styles.back, { [styles.disabled]: excludeBack.includes(pathname) })}
            onClick={() => navigate(-1)}
            arial-label="Retour">
            <IconBack />
          </button>
          <button className={styles.toggle} onClick={openMenu}>
            <span>
              <span className="u-sr-only">Menu</span>
            </span>
          </button>
        </div>

        <button
          className={classNames(styles.btn, { [styles.disabled]: !cmsPages.includes(pathname) })}
          onClick={cmsPages.includes(pathname) ? () => navigate(-1) : toggleModal}
          arial-label="Quitter">
          <IconClose />
        </button>

        <ul className={classNames(styles.language, { [styles.removed]: !showLanguages.includes(pathname) })}>
          <li className={lang === "fr" ? styles.active : ''} onClick={chooseLang}>fr</li>
          <li className={lang === "en" ? styles.active : ''} onClick={chooseLang}>en</li>
        </ul>
      </div>
    </header >
  )
}
