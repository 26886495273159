import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Sprite from '../../components/sprite/sprite'
import useStore from '../../store'
import { useTranslate } from '../../lib/utils'
import axios from 'axios'

import styles from './question-opinion.module.scss'
import RatingQuestion from "../../components/ratingquestion/ratingquestion";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";

/*const defaultAnswers = [
  ['Pesante', 'Supportable', 'Acceptable', 'Réjouissante', 'Agréable'],
  ['Décevant', 'Correct', 'Agréable', 'Chaleureux', 'Fabuleux'],
  ['Pas du tout', 'Trop peu', 'Pas vraiment', 'Incontestablement!', 'Tout à fait'],
  ['Pas du tout', 'Pas suffisamment', 'Moyennement', 'Grandement', 'Tout à fait'],
  ['Absolument pas', 'Partiellement', 'Modérément', 'Grandement', 'Parfaitement'],
]*/
//const links = [
//	{ name: "Home", url: "/" },
//];

export default function Opinion() {
  const params = useParams()
  const navigate = useNavigate()
  const id = Number(params.id)
  const setWhiteNav = useStore((state) => state.setWhiteNav);
  //const ally = useStore((state) => state.ally)
  const answers = useStore((state) => state.answers)
  const addAnswer = useStore((state) => state.addAnswer)
  const updateAnswer = useStore((state) => state.updateAnswer)
  const questions = useStore((state) => state.questions)
  const numQuestions = useStore((state) => state.numQuestions)
  const lang = useStore((state) => state.lang)
  //const setCurrentTicketId = useStore((state) => state.setCurrentTicketId)
  const currentTicketId = useStore((state) => state.currentTicketId)
  //const currentCampaign = useStore((state) => state.currentCampaign)
  const question = questions/*.filter((question) => question.questionType.id === 2)*/[id - 1]
  // if (question && (question.answers || []).length === 0) {
  const t = useTranslate();

  useEffect(() => {
    if (id === 5) {
      setWhiteNav(true);
    } else {
      setWhiteNav(false);
    }
  }, [id, setWhiteNav]);


  let nextLink = "";

  if (id === 1 || id === 3) {
    nextLink = `/question/${id}/anecdote`
  } else if (id === numQuestions) {
    nextLink = '/comment'
  } else {
    nextLink = `/question/${id + 1}`
  }


  const answer = (answers || []).find((item) => item.question === question['@id'])

  const initialValue = answer ? answer.rating - 1 : 2
  const [exp, setExp] = useState(initialValue)
  const [disabled, setDisabled] = useState(!answer)
  const image = require(`../../assets/images/opinion${id}.png`)

  useEffect(() => {
    if (numQuestions === 0 || !question) {
      navigate('/')
    }
  })

  const onSliderUpdate = (value) => {
    setDisabled(false)
    setExp(value)
  }

  const handleAnswer = (event) => {
    if (id === 4) {
      event.preventDefault();
      navigate('/comment');
    }

    const currentAnswer = { question: question['@id'], rating: exp + 1 }
    let currentAnswers = answers

    if (answer) {
      updateAnswer(currentAnswer);
      currentAnswers.map((answer) => {
        if (answer.question === currentAnswer.question) {
          answer.rating = currentAnswer.rating
        }
        return answer
      })
    } else {
      currentAnswers = [...currentAnswers, currentAnswer]
      addAnswer({
        question: question['@id'],
        rating: exp + 1,
      });
    }

    try {
      axios.put(process.env.REACT_APP_API_URL + 'tickets/' + currentTicketId,
        { ticketAnswers: currentAnswers },
        { headers: { 'Content-Type': 'application/json' } }
      )
    } catch (error) {
      console.log(error)
    }

  };

  const isSprite = () => {
    return id !== 2
  }

  // const imageStyle = id === 5 ? styles.imageForId5 : styles.image;
  return !numQuestions ? (
    <></>
  ) : (
    <>
      {id !== 5 && (
        <div
          className={classNames(
            'b-wrapper',
            'b-wrapper--no-padding',
            `u-bg-${id}`,
            styles.page
          )}
          data-question={id}
          data-step={exp + 1}
        >
          {!isSprite() && (
            <div className={styles.frames}>
              {[...Array(5)].map((_, i) => (
                <div key={i} className={styles.frame}></div>
              ))}
            </div>
          )}
          <div className={styles.content}>
            <div className={styles.inner}>

              <Breadcrumb />
              <div className={styles.ariane}>
                {id} / {numQuestions + 1}
              </div>
              <>

                <h4 className={styles.subtitle}>
                  <span>{t(question.relatedLabels.title)}</span>
                </h4>
                <p dangerouslySetInnerHTML={{ __html: question.label[lang] }}></p>
              </>
            </div>
            <div className={styles.image}>
              {isSprite() ? <Sprite frames={5} current={exp} image={image} id={id} ></Sprite> : <img src={image} alt="" />}
            </div>
          </div>
          <div className="">
            <div className="u-mb-2">
              <RatingQuestion
                onUpdate={onSliderUpdate}
                labelLeft={t(question.relatedLabels.negative_answer)}
                labelRight={t(question.relatedLabels.positive_answer)}
              />
            </div>
          </div>

          <div className={styles.continue}>

            <Link
              to={nextLink}
              className={classNames('b-btn', { 'b-btn--disabled': disabled })}
              onClick={(event) => handleAnswer(event)}
            >
              {t("continue")}
            </Link>

          </div>
        </div>
      )}
    </>
  )
}
