
export default function PageNotFound() {
    return <>
        <div className="b-wrapper b-wrapper--top">
            <div className="b-wrapper__content">
                <div className="b-wysiwyg">
                    <h1>Page non trouvée.</h1>
                </div>
            </div>
        </div>
    </>
}