import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './breadcrumb.module.scss';
import useStore from '../../store';


import allyImg1 from '../../assets/images/avatar-poisson.png';
import allyImg2 from '../../assets/images/avatar-giraffe.png';
import allyImg3 from '../../assets/images/avatar-zebre.png';
import allyImg4 from '../../assets/images/avatar-tigre.png';

const allyImages = [allyImg1, allyImg2, allyImg3, allyImg4];

export default function Breadcrumb({ currentId, chosenAllyIndex }) {
    const ally = useStore((state) => state.ally)
    const params = useParams();
    const id = currentId || Number(params.id);
    const numSteps = 5;

    const getAllyImg = () => {
        const chosenAllyIndex = ally - 1;
        if (chosenAllyIndex >= 0 && chosenAllyIndex < allyImages.length) {
            return `url(${allyImages[chosenAllyIndex]})`;
        }
        // default to fish image if no chosen ally or invalid index
        return `url(${allyImages[0]})`;
    };

    return (
        <div className={styles['breadcrumb-container']}>
            <div className={styles.breadcrumb}>
                {Array.from({ length: numSteps }, (_, i) => i + 1).map((step, index) => (
                    <React.Fragment key={step}>
                        {index !== 0 && <div className={styles.segment}></div>}
                        <div
                            className={`${styles.point} ${step === id ? styles.active : ''} ${index === 0 ? styles.first : ''} ${index === numSteps - 1 ? styles.last : ''}`}
                        >
                            {step === id && (
                                <div className={styles['img-container']} style={{ backgroundImage: getAllyImg() }}></div>
                            )}
                            <div className={styles['inner-point']}></div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
