import {useTranslate} from '../../lib/utils'  

export default function PersonalData() {
  const t = useTranslate();

  return (
    <div className="b-wrapper b-wrapper--top">
      <div className="b-wrapper__content">
        <div className="b-wysiwyg">
          <h1>{t("personal_data")}</h1>
          <p dangerouslySetInnerHTML={{ __html: t("personal_data_content") }} />          
        </div>
      </div>
    </div>
  )
}
