import {useTranslate} from '../../lib/utils'  

export default function Contact() {
  const t = useTranslate();
  const content = t("customer_service").replaceAll(
    "contact.client@hermes.com",
    "<a href='mailto:contact.client@hermes.com'>contact.client@hermes.com</a>"
  );

     
  return (
    <div className="b-wrapper b-wrapper--top">
      <div className="b-wrapper__content">
        <div className="b-wysiwyg">
          <h1>{t("contact_us")}</h1>
          <p dangerouslySetInnerHTML={{ __html: content }} /> 
        </div>
      </div>
    </div>
  )
}
