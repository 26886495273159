import { useEffect, useState } from 'react'
import styles from './sprite.module.scss'

const loadImage = (src, callback) => {
  const img = new Image()
  img.src = src

  img.onload = () => {
    callback({
      width: img.width,
      height: img.height,
    })
  }
}

export default function Sprite({ frames, current, image, id }) {
  const [imageSize, setImageSize] = useState({})

  useEffect(() => {
    loadImage(image, setImageSize)
  }, [image])

  const backgroundPosition = `left ${(current * 100) / (frames - 1)}%`;

  return (
    <>
      {imageSize === {} ? (
        <div></div>
      ) : (
        <div
          className={styles.wrapper}
          style={{ paddingTop: `${(imageSize.height / frames / imageSize.width) * 100}%` }}>
          <div
            className={styles.inner}
            style={{
              backgroundImage: `url(${image})`,
              backgroundPosition: backgroundPosition,
            }}></div>
        </div>
      )}
    </>
  );
}
