import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import useStore from '../../store'
import {useTranslate} from '../../lib/utils'


import styles from './question-anecdote.module.scss'

export default function QuestionAnecdote() {
  const params = useParams()
  const navigate = useNavigate()
  const id = Number(params.id)
  const questions = useStore((state) => state.questions)
  const numQuestions = useStore((state) => state.numQuestions)
  const setWhiteNav = useStore((state) => state.setWhiteNav)
  const getSelectedInterest = useStore((state) => state.getSelectedInterest)
  const anecdotes = useStore((state) => state.anecdotes)
  const anecdotesDisplayed = useStore((state) => state.anecdotesDisplayed)
  const addAnecdoteDisplayed = useStore((state) => state.addAnecdoteDisplayed)
  const lang = useStore((state) => state.lang)
  const [anecdote, setAnecdote] = useState('');
  
  const t = useTranslate();
  
  useEffect(() => {

    
    if(!anecdote || anecdote.length===0){
      if (questions.length === 0) {
        navigate('/')
      }
  
      
      var selectedAnecdotes = [];
    
      if (id === 1) {
        // vérifier si un métier a été choisi dans la première page
        const interest = getSelectedInterest();
        selectedAnecdotes = anecdotes.filter(item =>    
                    item.visitReason && item.visitReason.split("/").slice(-1)[0] === ""+interest.id
          );
        
      } 
      if(!selectedAnecdotes.length){
        selectedAnecdotes = anecdotes.filter(item => !item.visitReason);
      }
  
      var anec = selectedAnecdotes[0];
      
  
      selectedAnecdotes = selectedAnecdotes.filter((item) => {
        return anecdotesDisplayed.indexOf(item["@id"])===-1
      });
  


      if(selectedAnecdotes.length){
        // random un peu bizarre pour passer tests sonarqube
        const crypto = window.crypto || window.msCrypto;
        var arrayRand = new Uint32Array(1);
        crypto.getRandomValues(arrayRand); // Compliant for security-sensitive use caseses
        var rand = parseFloat("0." + arrayRand[0])
        var anecdoteIdx = Math.floor(rand*selectedAnecdotes.length); 
        anec = anecdotes.find(a => ""+a["@id"] === ""+selectedAnecdotes[anecdoteIdx]["@id"]);
      } 
  
      setAnecdote(anec.label[lang]);
      addAnecdoteDisplayed(anec["@id"]);

      if (id === 5) {
        setWhiteNav(true)
      }
    }
    // JB, utilisé pour masquer le warning qui empêche le déploiement à partir de la dev
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  //[setWhiteNav, id, questions, navigate, anecdotes, anecdote, setAnecdote, getSelectedInterest, lang, anecdotesDisplayed])
  

  return !questions.length ? (
    <></>
  ) : (
    <div className={classNames('b-wrapper', 'b-wrapper--no-padding', `u-bg-${id}`)} data-question={id}>
      <div className={classNames('b-bottom-sheet', styles.sheet)}>
        <div className="b-bottom-sheet__content">
          <div className={styles.inner}>
            <div className={styles.wrapper}>
              <div>
                <div className={styles.icon}>
                  <img src={require(`../../assets/images/picto-win${id}.png`)} alt="" />
                </div>
                <h2>{t("hermes_secrets")}</h2>
                <p>{anecdote}</p>
              </div>
              <div className={styles.btn}>
                <Link to={id < numQuestions ? `/question/${id + 1}` : '/comment'} className="b-btn">
                  {t("continue")}
                </Link>
              </div>

            </div>
          </div>
        </div>
        <div className={styles.anim}>
          <video autoPlay={true} muted={true} loop={true} playsInline={true}>
            <source type="video/mp4" src={`${process.env.PUBLIC_URL}/anims/anim${id}.mp4`} />
          </video>
        </div>
      </div>
    </div>
  )
}
