import useStore from '../store'

export const useTranslate = () => {
    const lang = useStore((state) => state.lang);
    const labels = useStore((state) => state.labels);

    return (field) => {
        try {
            return labels[field][lang]
        } catch (error) { }
        
        return lang + "  "+field
    }
}
