import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useStore from '../../store';
import {useTranslate} from '../../lib/utils'
import axios from 'axios'

import styles from './comment.module.scss';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';

export default function Comment({ currentId }) {
  //const params = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  //const rating = useStore((state) => state.rating);
  const heartQuestion = useStore((state) => state.heartQuestion);
  const answers = useStore((state) => state.answers);
  const comment = useStore((state) => state.comment);
  const setComment = useStore((state) => state.setComment);
  const setWhiteNav = useStore((state) => state.setWhiteNav);
  const setLogoVisibility = useStore((state) => state.setLogoVisibility);
  const numQuestions = useStore((state) => state.numQuestions);
  const currentTicketId = useStore((state) => state.currentTicketId)
  const currentQuestionNumber = numQuestions + 1;

  const t = useTranslate();

  useEffect(() => {
    setWhiteNav(true);
    setLogoVisibility(false);

    /*return () => {
      setComment('');
    };*/
  }, [setWhiteNav, setLogoVisibility, setComment]);

  const answer = (answers || []).find((item) => item.question === heartQuestion['@id'])
  console.log(answer);

  const onSubmit = async (formData) => {
    setComment(formData.comment);

    const data = {
      comment: formData.comment
    }

    const apiUrl = process.env.REACT_APP_API_URL + 'tickets/' + currentTicketId
    try {
      const response = axios.put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log(response)
    } catch (error) {
      console.log(error)
    }
    navigate('/personal-details');
  };

  return (
    <div className={classNames('b-wrapper', 'b-wrapper--no-padding', styles.page)}>
      <Breadcrumb currentId={5} />
      <div className={styles.questionCounter}>
        {currentQuestionNumber} / {currentQuestionNumber}
      </div>
      <div className="b-bottom-sheet">
        <div className="b-bottom-sheet__content">
          <form onSubmit={handleSubmit(onSubmit)}>
            {(answer && answer.rating) > 3 ? (
              <p>{t("like_most_about_your_visit")}</p>
            ) : (
              
              <p>{t("tip_to_improve")}</p>
            )}
            <textarea
              {...register('comment')}
              defaultValue={comment}
              placeholder={t("comment_or_continue")}
              className="b-control b-control--textarea"></textarea>
            <div className="b-bottom-sheet__btn">
              <button className="b-btn">{t("continue")}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
