import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Layout from './components/layout/layout'

import Home from './pages/home/home'
import Choose from './pages/choose/choose'
import Info from './pages/info/info'
import Question from './pages/question/question'
import QuestionDetails from './pages/question-details/question-details'
import QuestionOpinion from './pages/question-opinion/question-opinion'
import QuestionAnecdote from './pages/question-anecdote/question-anecdote'
import Comment from './pages/comment/comment'
import Bravo from './pages/bravo/bravo'
import PersonalDetails from './pages/personal-details/personal-details'
import Why from './pages/why/why'
import PersonalData from './pages/personal-data/personal-data'
import LegalMentions from './pages/legal-mentions/legal-mentions'
import Contact from './pages/contact/contact'
import End from './pages/end/end'

import './styles/globals.scss'
import reportWebVitals from './reportWebVitals'
import PageNotFound from "./pages/404/404";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home lang={"fr"} country={"FR"} />} />
          <Route path="/fr" element={<Home lang={"fr"} country={"FR"} />} />
          <Route path="/ch" element={<Home lang={"en"} country={"CH"} />} />
          <Route path="/us" element={<Home lang={"en"} country={"US"} />} />
          <Route path="/uk" element={<Home lang={"en"} country={"GB"} />} />
          <Route path="/hk" element={<Home lang={"en"} country={"HK"} />} />
          <Route path="/choose" element={<Choose />} />
          <Route path="/info" element={<Info />} />
          <Route path="/question/:id" element={<Question />} />
          <Route path="/question/:id/details" element={<QuestionDetails />} />
          <Route path="/question/:id/opinion" element={<QuestionOpinion />} />
          <Route path="/question/:id/anecdote" element={<QuestionAnecdote />} />
          <Route path="/comment" element={<Comment />} />
          <Route path="/bravo" element={<Bravo />} />
          <Route path="/personal-details" element={<PersonalDetails />} />
          <Route path="/why" element={<Why />} />
          <Route path="/personal-data" element={<PersonalData />} />
          <Route path="/legal-mentions" element={<LegalMentions />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/end" element={<End />} />
          <Route path="*" element={<PageNotFound />} />
          {/* <Route index element={<End />} /> */}
        </Routes>
      </Layout>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
