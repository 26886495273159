import { Link } from 'react-router-dom'
import {useTranslate} from '../../lib/utils'



export default function Why() {
  const t = useTranslate();
  const content = t("why_this_site_content");
  
  return (
    <div className="b-wrapper b-wrapper--top">
      <div className="b-wrapper__content">
        <div className="b-wysiwyg">
          <h1>{t("why_this_site")}</h1>
          
          <p>
            <span dangerouslySetInnerHTML={{ __html: content }} />
            <Link to="/legal-mentions">{t("click_here")}</Link>
          </p> 
        </div>
      </div>
    </div>
  )
}
