import classNames from 'classnames'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../../store'
import {useTranslate} from '../../lib/utils'

import styles from './choose.module.scss'

import allyThumb1 from '../../assets/images/thumb-ally1.png'
import allyThumb2 from '../../assets/images/thumb-ally2.png'
import allyThumb3 from '../../assets/images/thumb-ally3.png'
import allyThumb4 from '../../assets/images/thumb-ally4.png'

const allies = [
  {
    label: "ride_the_wave",
    image: allyThumb1,
  },
  {
    label: "be_spot_on",
    image: allyThumb2,
  },
  {
    label: "stripe_the_pose",
    image: allyThumb3,
  },
  {
    label: "go_wild",
    image: allyThumb4,
  },
]

export default function Choose() {
  const navigate = useNavigate()
  const [chosen, setChosen] = useState(false)
  const ally = useStore((state) => state.ally)
  const setAlly = useStore((state) => state.setAlly)
  const t = useTranslate();

  const choose = (ally) => {
    setChosen(true)
    setAlly(ally)
    document.body.dataset.ally = ally
    //document.body.classList.remove('body-ally-1');
    //document.body.classList.remove('body-ally-2');
    //document.body.classList.remove('body-ally-3');
    //document.body.classList.remove('body-ally-4');
    //document.body.classList.add('body-ally-' + ally);
  }

  return chosen && ally !== 0 ? (
    <div className={styles.anim} onClick={() => navigate('/info')}>
      <video autoPlay={true} muted={true} playsInline={true} onEnded={() => navigate('/info')}>
        <source type="video/mp4" src={`${process.env.PUBLIC_URL}/anims/ally${ally}.mp4`} />
      </video>
    </div>
  ) : (
    <div className={classNames('b-wrapper b-wrapper--center', styles.page)}>
      <div className={styles.content}>
          <h2>{t("choose_you_companion")}</h2>
          <p>{t("they_will_guide_you")}</p>
      </div>
      <ul className={styles.allies}>
        {allies.map((e, i) => (
          <li key={i} className={styles.item}>
            <button className={styles.btn} onClick={() => choose(i + 1)}>
              <img src={allies[i].image} alt="" className={'animal' + i} />
              <p>{t(allies[i].label)}</p>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
