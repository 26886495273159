import { useEffect } from 'react'
import WebFont from 'webfontloader'

import Header from '../header/header'
import Nav from '../nav/nav'
import Modal from '../modal/modal'

export default function Layout({ children }) {
  const appHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  useEffect(() => {

    /*let startY;
    window.addEventListener("touchstart", function(e){
        startY = e.changedTouches[0].screenY;
    });

    window.addEventListener("touchmove", function(e){
        let currentY = e.changedTouches[0].screenY;
        if (window.pageYOffset <= 0 && startY <= currentY) {
            e.preventDefault();
            return false;
        }
    },{passive:false});*/


    WebFont.load({
      google: {
        families: ['Arvo', 'Spectral:300'],
      },
    })

    window.addEventListener('resize', appHeight)
    appHeight()
  }, [])

  return (
    <>
      <Header />
      <Nav />
      <main>{children}</main>
      <Modal />
    </>
  )
}
